@media (min-width: 1300px) {
	.container {
	  max-width: 1280px;
	}
	.cle-menu2{
		right: 8% !important;
	}
	.popup-content-Taz{
		left: 85% !important;
	}
	.LushMoreSpace{
		margin-left: 8%;
	}
  }
  @media (max-width: 1299px) {
  .popup-content-Taz{
	left: 99%;
}
.cle-menu2{
	right: -7% !important;
}

  }
  @media (min-width: 400px) {
	.poweredbylogoLush{
		position: relative;
        left: 170px;
        min-width: 22%;
        min-height: 20%;
        width: 32%;
        margin-top: -32%;
        margin-right: 0%;
        top: 52px !important;
    
	}
	.lushThumbahh{
width: 97%;
	}
	.pwrdbyLush{
		position: relative;
        top: 15px;
        white-space: nowrap;
        left: 100px;
        width: 16%;
        margin-top: -10%;
        margin-right: 0%;
        font-size: 14px;
}
.marLushTop{
	margin-top: 25px;
}
.filter-top {
	top: 92% !important;
	z-index: 99;
}
  }
  .product_offer_card{
	position: absolute;
	cursor: pointer;
}
  @media (max-width: 399px) {
	.lushThumbahh{
		width: 96%;
	}
	.filter-top{
		top: 105.5% !important;
		z-index: 99;
	}
	.marLushTop{
		margin-top: 20px;
	}
.pwrdbyLush{
	position: relative;
        top: 15px;
        white-space: nowrap;
        left: 100px;
        width: 16%;
        margin-top: -10%;
        margin-right: 0%;
        font-size: 14px;
}

.poweredbylogoLush{
	position: relative;
	/* height: 55px; */
	left: 174px;
	min-width: 22%;
	min-height: 20%;
	width: 32%;
	margin-top: -28%;
	margin-right: 0%;
	top: 37px !important;
	}
  }
  @media (min-width: 1360px) {
	.header-special{
		margin-top: 3%
	}
  }
  @media (max-width: 1350px) {
	.header-special{
		margin-top: 4%
	}
  }
  @media (min-width: 1200px) {
	.container {
		max-width: 93% !important;
	}
}
  /*@media (min-width: 992px) {
	  .navbar-toggler {
		  display: none;
	  }
	  .btn.close-btn {
		  display: none;
	  }
	  
  }*/
  .rounder-corner-nav .owl-theme .owl-nav .owl-prev .fa, .rounder-corner-nav .owl-theme .owl-nav .owl-next .fa {
    color: white;
    font-size: 36px !important;
	border-radius: 6px !important;

}
.rounder-corner-nav .owl-theme .owl-nav .owl-prev , .rounder-corner-nav .owl-theme .owl-nav .owl-next{
	pointer-events: auto;
	color: white ;
	background-color: #a9c70b !important;
    font-size: 30px;
	border-radius: 6px !important;
	width: 50px !important;
    height: 50px !important;
	padding: 0px 13px !important;

}

.rounder-corner-nav .owl-theme .owl-nav .owl-prev, .rounder-corner-nav .owl-theme .owl-nav .owl-next {
	pointer-events: auto;
	width: 50px !important;
    height: 50px !important;

}
.dropdown-togglecat:after {
    margin-left: 0.255em !important;
    vertical-align: 0.1em !important;
    content: "";
    border-top: 0.3em solid !important;
    border-right: 0.3em solid transparent !important;
    border-bottom: 0 !important;
    border-left: 0.3em solid transparent !important;
	color: #ffffff;
	position: absolute;
    top: 50%;
}
.dropdown-toggle:after {
    margin-left: 0.255em !important;
    vertical-align: 0.1em !important;
    content: "";
    border-top: 0.3em solid !important;
    border-right: 0.3em solid transparent !important;
    border-bottom: 0 !important;
    border-left: 0.3em solid transparent !important;
	color: #6f6e6e;
}
.rounder-corner-nav .owl-theme .owl-nav .owl-prev, .rounder-corner-nav .owl-theme .owl-nav .owl-next {
    border-radius: 20px;
    width: 40px;
    height: 40px;
    border: 2px solid #a9c70b !important;
}
.collection-filter .layerd-navigation {
    padding: 10px 10px 0px 10px !important;
    border-bottom: none !important;
}
.card-body{
	padding: 10px 1.25rem 10px 1.25rem !important;
}
.collection-filter {
	background-color: #fff;
	.filter-top {
	  border-bottom: none !important;
	  .filter-clear {
		padding: 10px;
		display: flex;
		// justify-content: end !important;
	  }
	}
	.layerd-navigation {
	  padding: 10px 10px 0px 10px !important;
	  // font-family: "Inter-Regular";
	  border-bottom: none !important;
	  .collection-collapse-block {
		  margin-bottom: 10px;
		  
	  .collection-collapse-block-content {
		  padding-left: 30px;
		  
		  .options {
			  margin: 8px 0px 8px 0px;
			  input {
				  margin-right: 10px;
			  }
			  label{
				  margin-bottom: 0px;
				  font-size: 15px;
				  .count {
					  color: #afabab;
					  font-size: 14px;
				  }
			  }
		  }
	  }
	}
  }
  }
.sublist-font2 :hover{
	color: #43950d !important;
}
.baby-rotate

{
	display: flex;
    justify-content: end;
    margin-top: 2%;
    cursor: pointer;
	
	.collapsed{
	transform: rotate(270deg);
}
}
.baby-rotate1

{
	display: flex;
    justify-content: end;
    margin-top: 2%;
    cursor: pointer;
	
	.collapsed{
	transform: rotate(90deg);
}
}
  .new_miles {
    width: 100%;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    padding: 15px 5px;
    box-shadow: 0 5px 6px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
    min-height: 210px;
    position: relative;
    background: linear-gradient(to bottom, #d8d8d8, #e8e9ee);
  }
  .new_miles p{
  text-align: center;
    font-size: 16.5px;
    line-height: 1.2;
  }
  .miles_icon img{
	width: 50px;

  }
  .main_about_left img{  width: 100%;
    height: auto;
}
.main_about_right h2 {
	font-size: 35px;
    margin-top: 5px;
	font-weight: 700;
    color: #000;
}
.main_about_right h4 {
	font-size: 21px;
    margin-top: 10px;
	font-weight: 700;
    color: #000;
}
.main_about_right p {
font-size: 20px;
margin-top: 10px;
text-align: justify;
line-height: 1.6;
}
.miles_icon{
  text-align: center;
    margin-bottom: 10px;
  }
  .new_miles h4 {
    text-align: center;
    margin: 4px 0px;
    position: absolute;
    bottom: 2%;
    left: 30%;
    z-index: 9;
    color: #fff;
    font-weight: 500;
    font-size: 27px;
  }
  .new_miles::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 45px;
    left: 0px;
    bottom: 0px;
    background: #2f8df1;
    box-shadow: 0 5px 6px 1px rgba(0, 0, 0, 0.2);
}
  .main_miless {
    background-image: url(http://www.specialwires.in/wp-content/themes/specialwire/images/miless.jpg);
    overflow: hidden;
    position: relative;
    padding: 60px 0px;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
.sort {
    margin-left: 65%;
    font-size: 13px;
	display: flex;
}
  @media (min-width: 991px) {
	.trers-grid-image {
	  img {
		max-width: 150px;
	  }
	}
  }
  
  @media (max-width: 1199px) {
	footer{
        .footer-links {
            display: block;
        }
    }
	.free-shipping-section {
	  ul {
		flex-flow: wrap;
		li {
		  margin-bottom: 15px;
		}
	  }
	}
	body {
	//   padding-top: 47px;
	  padding-top: 0px;
	}
  
	.header {
	  .flex-container {
		.header-row-one {
		  display: block;
		  .account-section {
			position: absolute;
			top: 0;
			width: 100%;
			border-bottom: 1px solid #00000012;
			left: 0;
			z-index: 99;
			background: #fff;
			.account {
			  justify-content: flex-end;
    		 margin-top: 10px;
			}
		  }
		}
	  }
	}
	.dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 100%;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		z-index: 1;
	  }
	  
	  .dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
	  }
	  
	  .dropdown-content a:hover {
		background-color: #ddd;
	  }
	  
	  .dropdown:hover .dropdown-content {
		display: block;
	  }
  }
  @media (max-width: 991px) {

	.product-details-wrapper {
		.slider-nav {
			bottom:6px;
			
		}
		.details-wrapper {
			
			.product-all-details {
				.row {
					.col-left {
						flex: auto;
					}
				}
				.product-addtional-details{
					padding-bottom:15px;
					&:before {
						width:100%;
					}
				}
			}
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 30px;
		  }
		}
	  }
	/**Product**/
	.product-box-inner {
	  .product-image {
		max-width: 150px;
	  }
	  .product-detail {
		.price-wrapper {
		  position: inherit;
		  transform: none;
		}
		.add-to {
		  position: inherit;
		}
	  }
	  .verified {
		top: 10px;
	  }
	}
  
	.best-products {
	  .row-fluid {
		display: block;
		.tyers-grid {
		  justify-content: space-between;
		  li {
			display: inline-block;
			margin: 15px 0px 15px 0px;
			text-align: center;
		  }
		}
	  }
	  .column {
		&.single-image {
		  margin-top: 15px;
		}
	  }
	}
  }
  
  @media (max-width: 767px) {
	.mob-filter{
		
		z-index: 200;
		background-color: $bg-color;
		width: 100%;
		top: 0;
    	bottom:0;
		left:0;
    	position:fixed;
    	overflow-y:scroll;
    	overflow-x:hidden;
	}

	.filter-button{
		display: block !important;
		position: fixed;
    	bottom: 4%;
    	left: 45%;
   		width: 50%;
    	height: 8%;
		border-radius: 50px;
		border: 2px solid $primary;
		font-size: 18px;
		font-weight: 600;
		-webkit-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		-moz-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
	}


	.details-wrapper {
		padding-right: 0px !important;
    	padding-left: 0px !important;
    	margin-right: 0px !important;
    	margin-left: 0px !important;
		.slider-nav {
			bottom:6px;
			max-width: 240px;
			min-width: 230px;
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 20px;
		  }
		}
	  }
	.aside-group {
	  display: block;
	}
  
	/**Title**/
	.content-title {
	  .title-box {
		.title {
		  &:after {
			display: none;
		  }
		}
	  }
	}
  
	.header {
	  .flex-container {
		display: block;
		.logo {
		  text-align: center;
		//   padding: 15px;
		  margin:0px auto;
		}
		.header-row-one {
			.search-bar-group {
				.search-bar {
					margin-top:10px;
					margin-right: -4rem;
					width:100% !important;
					
				}
			}
		}
	  }
	}
  
	.content-1-section {
	  position: static;
	  border-radius: 0px;
	}
	.homepage-banner-slider {
	  .owl-theme {
		.owl-dots {
		  bottom: 0;
		}
	  }
	}
  
	.best-products {
	  .column {
		.tyers-grid {
		  li {
			display: block;
			text-align: center;
		  }
		}
	  }
	}
  }
  @media (max-width: 640px) {
.special-trash{
	left: 157% !important;
}
	.checkout-btn{
		min-width:fit-content; 
		padding-left:0px !important; 
		height:30px !important;
		position: relative;
		top: 0px;
		width: 100px !important;
		margin-top: 16%;
	}
	
	.collection-wrapper {
	  .col-grid-box {
		padding: 0px 10px 0px 10px;
	  }
	}
	
	footer{
        .footer-links {
            display: grid !important;
        }
		.footer-col{
			justify-content: center !important;
			padding: 5px;
			margin:5px;
		}
    }

	.banner-content {
	  .content {
		h2, h1 {
		  font-size: 18px;
		}
	  }
	}
	.header {
	  .flex-container {
		.header-row-one {
		  .search-bar {
			margin-left: 0px;
		  }
		  .account-section{
			position: fixed !important;
			z-index: 2 !important;
			padding-right:0px;
			.account{
				margin-right: 10px !important;
			}
			.dropdown-toggle{
				font-size:10px !important;
			}
			.fa-user{
				font-size:10px !important
			}
		  }
		}
	  }
	}


	.product-box-inner {
	  display: block;
	  padding: 10px 0px 10px 0;
	  .img-wrapper {
		padding-bottom: 10px;
		.product-image {
		  max-width: 100%;
		  width: 100%;
		}
	  }
  
	  .product-detail {
		padding-left: 0px;
	  }
	  .verified {
		top: 10px;
		transform: none;
	  }
	}
  
	/*Pagenation**/
	.product-pagination {
	  .row {
		display: block;
		> div {
		  margin-bottom: 10px;
		  text-align: center;
		  p {
			margin-bottom: 0px;
		  }
		  .pagination {
			justify-content: center;
		  }
		}
	  }
	}
	.mobile-filter{
		display: block !important;
	  }
	.desktop-filter{
		display : none !important;
	}
	.mob-categories{
		
		opacity: 1;
		padding:10px;
		font-family: 'proxima-regular','sans-serif'!important;
		position: fixed;
		background-color: white;
		z-index: 100;
		width: 100%;
		height: 100%;
		border-right: 50px solid rgba(0, 0, 0, 0.5);
		overflow-y: auto;
		top:0;
		left:0;
		background-clip: padding-box;
		
		
	}
	.touch-out{
		 background-color:transparent;
		 position: fixed;
		 z-index: 999;
		 width: 60px;
		 right: 0px;
		 height:300%;
		 top:0px;
		 overflow:hidden;
		
	}
	// body{
	// 	position: fixed !important;
	// }

  }
  /*** Range **/
  
  @media (max-width: 991px) and (min-width: 640px) {
	.product-name {
	  font-size: 18px;
	}
	.price {
	  font-size: 18px;
	}
  
	.product-price {
	  .tax-includes {
		font-size: 13px;
	  }
	}
	.verified {
	  font-size: 13px;
	}
	.add-to {
	  .btn-solid {
		font-size: 15px;
	  }
	}
  }


  @media (min-width: 1300px){
	section .star{
		width: 100%;
		}
	
  }


@media (max-width: 640px) {
	section .star{
		width:87%;
		margin: 25px;
		.review{
			width: 97%;
			transform: translate(0px, 17px);
		}
	}
}

@media (min-width: 1300px) {
	
	
	footer{
        .footer-links {
            display: block;
        }
    }
	footer{
        .soc-icons{
            display: flex;
        }
    }
}

@media (max-width: 640px) {
	
	.slider{
		display:flex;
		width: 91%;
		transform: translate(10px, -10px);
	  .content {
		h2, h1 {
		  font-size: 18px;
		}
	  }
	}

footer{
        .soc-icons {
            display: grid !important;
			margin-left: -10px;
			
        
		.footer-col{
			justify-content: center !important;
			padding: 5px;
			margin:5px;
			min-width:136%;
			
			.social-icons1{
			max-width: 370px;
			}
		}
	}
    }
}

@media (max-width: 640px) {
	.specialwire-listing-price{
			font-size: 16px;
			color: #2d5986;
			font-weight: 700;
	}
	.special-listing-price{
		font-size: 12px;
		color: black;
		font-weight: 700;
	}
	.avonbanner{
		
		margin-top: 20px;
		width: 100%;
		object-fit: contain;
		}
		.dropdown{
			width: 100%;
		}
.cartscr{
				display: block;
			}
			.special-foot-email{
				margin-left: -15px;
			}
			.special-subbtn{
				color: white; 
				background-color: #0095da;
				height:40px;
				width:138px !important;
			}
			.product-list-tile3{
				width: 307px;
				display: flex !important;
				justify-content: center;
				margin-left: 5px;
				color: #2d5986;
				font-size: 16px !important;
				//color: #000 !important;
				font-weight: 700 !important;
				overflow: hidden !important;
				
				text-overflow: ellipsis !important;
				height: 38px;
				text-transform: uppercase;
				// background-image: linear-gradient(
				//   -225deg,
				//   #231557 0%,
				//   #44107a 29%,
				//   #ff1361 67%,
				//   #fff800 100%
				// );
				// background-size: auto auto;
				// background-clip: border-box;
				// background-size: 200% auto;
				// color: #fff;
				// background-clip: text;
				// text-fill-color: transparent;
				// -webkit-background-clip: text;
				// -webkit-text-fill-color: transparent;
				// animation: textclip 2s linear infinite;
				display: inline-block;
					font-size: 190px;
				//text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px grey;
			  }
	}
	
	@media (min-width: 640px) {
		.baby_card_body{
			padding-top: 2px !important;
		}
		.logo-section3{
		background-color: #fff!important;
		margin-top: 10px;
		margin-left: 120px;
		margin-bottom: 10px;
	}
		.specialwire-listing-price{
			font-weight: bolder;
    		font-size: 1.0rem;
			color: #2d5986;
	}
		.product-list-tile3 {
			color: #2d5986 !important;
			font-size: 16px!important;
			font-weight: 700!important;
			overflow: hidden!important;
			display: -webkit-box!important;
			-webkit-line-clamp: 2!important;
			-webkit-box-orient: vertical!important;
			text-overflow: ellipsis!important;
			display: inline-block;
			font-size: 190px;
		}
		.dropspecial{
			width: 50%;
			border-color: #4890ba;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
		}
		.dropspecial1{
			width: 300%;
			border-color: #4890ba;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
		}
		.special-foot-email{
			margin-left: -15px;
		}
		.special-subbtn{
			color: white; 
			background-color: #0095da;
			height:40px;
			width:138px !important;
		}
		.cartscr{
			
			width: 100%;
		}
		.listdetail{
			width: 77%;
    margin-left: 7px;
		}
		.dropdown{
			width: 100%;
		}
		.rounder-corner-nav .owl-theme .owl-nav .owl-prev:hover , .rounder-corner-nav .owl-theme .owl-nav .owl-next:hover{
			color: #a9c70b !important;
			background-color: #ffffff !important;
			font-size: 30px !important;
			border-radius: 6px !important;
			pointer-events: auto;
			cursor: pointer;
			width: 50px !important;
			height: 50px !important;
			padding: 0px 13px !important;
			.fa{
				color: #a9c70b !important;
			}
		}
	.avonbanner{
		width: 100%;
		
		color: white;
		object-fit: contain;
		// height: 73vh;
		}
		.vim_dropdown{
			overflow-y: auto !important; 
 		 padding: 5px !important; 
		}
		.vim_dropdown::-webkit-scrollbar {
			width: 6px !important;               /* Thin scrollbar width */
		  }
		  
		  .vim_dropdown::-webkit-scrollbar-thumb {
			background-color: #e3e2e2;   /* Color of the scrollbar thumb */
			border-radius: 3px;       /* Rounded corners for thumb */
		  }
		  
		  .vim_dropdown::-webkit-scrollbar-thumb:hover {
			background-color: #767575;   /* Darker color on hover */
		  }
		  
		  .vim_dropdown::-webkit-scrollbar-track {
			background: #f1f1f1;      /* Background color of scrollbar track */
		  }
		  .no_image_Lush{
			width: 100% !important;
			height: 100% !important;
		  }
		  .width_addToCartLush{
			width: 85% !important;
		  }
		  .schift_key_offer{
			font-family: Trebuchet MS, sans-serif !important;
font-size: 14px;
background-color: #1a781b;
color: white;
border-radius: 40px;
padding-left: 8px;
padding-right: 8px;
		}
		.lush-strikelist{
			font-size: 13px !important;
			text-decoration: line-through;
			color: #8080806e !important;
			font-weight: 500;
			margin-top: 3%;
			text-decoration: line-through;
		}
		.lush-strikedetail{
			font-size: 13px !important;
			text-decoration: line-through;
			color: #8080806e !important;
			font-weight: 500;
			margin-top: 2%;
			text-decoration: line-through;
		}
	}
	@media (max-width: 640px) {
		.width_addToCartLush{
			width: 40% !important;
		  }
		.Lush_check_price{
			bottom: 120%;
		}
		.about-title{
			margin-left: 15px;
			margin-right: 15px;
		}
		.babymarker::marker{
			color: #ffffff !important;
		}
		.logo-images2{
			width: 100px;
			height: 108%;
		}
		.thumb1-baby{
			display: grid;
    		grid-template-columns: repeat(2,1fr);
		}
		.product-list-description-baby{
			display: grid;
			}
			.product-list-description-baby22{
				display: flex;
				margin-left: 20px;
			    margin-bottom: 20px;	
			}
			
			.product-list-image-baby{
				width: 100%;
				border-radius: 150px;
				
			}
			.product-center-baby{
			width: 100%;
			margin-top: 20px;
			}
			.product-center-baby22{

			width: 80%;
			margin-top: 20px;
				}
			.baby-age1{
				display: grid;
				grid-template-columns: repeat(2,1fr);
			}
			.inq-Caro-st{
     margin-bottom: 10px !important;
			}
			.baby-age-list{
				text-align: center;
				font-family: sans-serif;
				line-height: 1.25em;
				font-size: 11px;
				margin-top: 5px;
				font-weight: 450;
				color: black;
			}
			.baby-newcollection{
				width: 100%;

				border-radius: 10px;
			}
			.newcollection-image-baby{
				width: 100%;
			}
			.product-centering-baby{
				width: 90%;
				background-color: white;
				margin-top: 25px;
				border-radius: 10px;
			}
			.baby-newcol-price{
				font-size: 14px;
				color: #80c340;
				font-weight: 500;
			}
			.lush-strikehome{
				font-size: 13px !important;
				margin-left: 5px !important;
				text-decoration: line-through;
				color: #8080806e !important;
				font-weight: 500;
				margin-top: 2%;
				text-decoration: line-through;
				color: #8080806e !important;
			}
			.lush-strikelist{
				font-size: 13px !important;
				margin-left: 5px !important;
				text-decoration: line-through;
				color: #8080806e !important;
				font-weight: 500;
				margin-top: 2%;
				text-decoration: line-through;
			}
					.baby-newcol-name
					{
						text-align: center;
						font-size: 14px;
						font-family: 'proxima-regular';
						font-weight: 700;
						overflow: hidden!important;
    display: -webkit-box!important;
    -webkit-line-clamp: 2!important;
    -webkit-box-orient: vertical!important;
    text-overflow: ellipsis!important;
    height: 40px;
					}
					.baby_newprodname{
						margin-top:15px;
						margin-bottom: 15px;
						margin-left: 15px;
						   margin-right: 15px;
					}
					.justify-center-baby{
						justify-content: center;
					}
					.head-tail-baby{
						margin-left: 30px;
						margin-right: 30px;
					}
					.head-tail1-baby{
						text-align: center;
					}
					.baby-trasform-bestseller{
						transform: translate(0px,-25px);
					}
					.sale-btn{
						width: 126px;
						font-size: 14px !important;
						height: 50px;
						border: none; 
						font-family: Trebuchet MS, sans-serif !important;
						font-weight: 550 !important; 
						background-color: #bb086c;
						color: white;
					
				}
				.social-icons2{
					justify-content: left !important;
				}
				.filter_button{
					font-size: 12px !important;
					border: none; 
					font-family: Trebuchet MS, sans-serif !important;
					font-weight: 550 !important; 
					padding: 5px 10px;
					margin-top: 20px;
					margin-right: 10px;
					// padding-right: 10px;
					// padding-left: 10px;
					width: fit-content;
					border-radius: 6px;
			}
			.img-baby-height{
				width: 96%;
				object-fit: contain;
				  height: 172.79px;
				  margin-top: 30px;
			}
			.scift-left-best2{
				margin-top: 30px;
			}
			.margin-baby-left
		{
			margin-left: 0px !important;
		}
		.cmp_logo_height{
			height: 49px;
		}
		.baby-width{
			width: 100%;
		}
		.flex-baby-flex{
			margin-top: 30px;
			display: flex;
			justify-content: center;
		}
		.cartaddfun{
			display: flex;
		}
		.schift_key_offer{
			font-family: Trebuchet MS, sans-serif !important;
font-size: 12px;
background-color: #1a781b;
color: white;
border-radius: 40px;
padding-left: 8px;
padding-right: 8px;
		}
		.baby-age{
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			margin-left: 11%;
			width: 82%;
			column-gap: 4px;
			margin-bottom: 12%;
		}
		.product-list-cards-baby2{
			margin-left: 10px;
    		margin-right: 10px;
		}
		// .product-list-cards-Lushnn{
		// 	margin: 10px !important;
		// }
		.product-list-cards-lush2{
			margin-left: 5px;
    		margin-right: 10px;
		}
		
		.product-list-cards-Lush2{
			margin-left: 5px;
    		margin-right: 10px;
		}
		.lushoverlay{
			transform: translate(10px, -200%) !important;
			position: absolute;
		  }
		  .lushListPOS{
			padding-bottom: 45px;
border-bottom: 1px solid #d5d5d5;
}
		  .lushCardMarTB{
			margin-top: 10%;
    margin-bottom: 12%;
		  }
		  .lushoverlay1{
		  transform: translate(0px,-170%);
		  right: 35%;
		  position: absolute;
		  }
		  .lushovertext1{
			white-space: nowrap;
			font-size: 20px;
			font-weight: 500;
			font-family: Franklin Gothic Medium,Arial Narrow,Arial,sans-serif;
			letter-spacing: 2px;
		  }
		  .lushovertext2{
			text-align: left;
			font-family: sans-serif;
			letter-spacing: 1px;
			font-size: 10px;
			margin-top: 5px;
		  }
		  .btlush{
			font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
			font-size: 9px;
			position: relative;
			top: 20px;
			padding: 12px;
			border: 1px solid #a5c30b;
			letter-spacing: 1px;
		  }
		  .btlush1{
			font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
			font-size: 13px;
			padding-top: 5px;
			   padding-bottom: 5px;
			position: relative;
			top: 16px;
			padding-right: 10px;
			padding-left: 10px;
			border: 2px solid #a5c30b;
			letter-spacing: 1px;
		  }
		  .thumb2-lush {
			margin-left: 2%;
			margin-right: 2%;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			
		  }
		  .thumb2-lush_detail {
			// margin-left: -2%;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			
		  }
		  .lushhomebes1{
			font-size: 18px;
			font-weight: 700;
			font-family: 'proxima-regular';
			letter-spacing: 3px;
			text-align: center;
		  }
		  .lushhomebes2{
			font-size: 14px;
			font-weight: 500;
			font-family: sans-serif;
			letter-spacing: 2px;
			text-align: center;
			color: #3d3d3d;
			margin-bottom: 30px;
		  }
		  .lushhomebes3{
			font-size: 22px;
			font-weight: 500;
			font-family: Franklin Gothic Medium,Arial,sans-serif;
			letter-spacing: 2px;
			text-align: center;
			color: #ffffff;
			margin-top: 5px;
		  }
		  .lushhomebes4{
			font-size: 14px;
			font-weight: 500;
			font-family: sans-serif;
			letter-spacing: 2px;
			text-align: center;
			color: #ffffff;
			margin-bottom: 30px;
			margin-top: 10px;
		  }
		  .lushgrid{
			display: grid;
			grid-template-columns: repeat(4,1fr);
		  }
		  .lushsubscribe{
			font-size: small;
			width: 70%;
			padding-top: 5px;
			padding-bottom: 5px;
		
		  }
		  .lush-subbtn{
			width: 30%;
			border: none;
		  }
		  .lushhomesec-1{
			margin-top: 30px;
			// display: flex;
		  }
		  .lushhomecard-1{
			width: 94%;
			   margin-left: 3%;
			   margin-right: 3%;
		  }
		  .lush-imglogo{
			width: 100%;
		}
		.special-listing-width{
			border-top: 1px solid gray;
			margin-left: 0px !important;
		}
		.baby-newcol-name1
		{
			color: #f4f3f3;
			font-size: 18px;
    		font-weight: 700;
			font-family: system-ui;
		}
		.lush-newcol-name{
			font-size: 15px;
    		color: white;
			overflow: hidden !important;
			display: -webkit-box !important;
			-webkit-line-clamp: 8 !important;
			-webkit-box-orient: vertical !important;
			text-overflow: ellipsis !important;
			background-clip: text;
			-webkit-background-clip: text;
			display: inline-block;
		}
		.lush-newcol-name1{
			font-size: 12px;
    		color: white;
		}
		.lush_footer_text{
			margin: 10px 0px !important;
			font-family: Trebuchet MS, sans-serif!important;
			font-size: 16px !important;
			font-weight: 500;
		}
		.lush_footheading{
			font-family: Trebuchet MS, sans-serif!important;
			font-size: 18px !important;
			font-weight: 600;
	}
	}

	@media (max-width: 1300px) {
		.baby_height_gender{
			height: 466px;
		}
		.lushoverlay{
			transform: translate(10px, -275%);
		  }
	}
	@media (min-width: 1300px) {
		.baby_height_gender{
			height: 563px;
		}
		.lushoverlay{
			transform: translate(10px, -300%);
		  }
	}
	@media (min-width: 640px) {
		
		.lush-filter{
			background-color: #f7f7f7;
		}
		.baby_mar_top{
			margin-top: 110px;
		}
		.babys_padding{
			padding-bottom: 70px;
		}
		.babys_padding1{
			padding-bottom: 100px;
		}
		.baby_beforefooter{
			width: 100%;
		}
		.babycar{
			width: 30%;
		}

		// .baby_height_gender1{
		// 	height: 430px;
		// }
		.Taz_wid_cart{
			width: 100%;
		}
		.Lush_Padll{
			padding-top: 4px;
		}
		.inq-menu-left-st{
			border: none !important;
		}
		.banner_baby{
			margin-bottom: 0px !important;
		}
		.thumb1-baby {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			
		  }
		  .thumb2-lush {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			
		  }
		  .thumb2-lushlist{
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		  }
		  .thumb2-lush_detail{
			margin-left: 3%;
			width: 94%;
		  }
		.cartaddfun{
			display: flex;
		}
		.image-88baby{
			width: 91%;
		}
		.cmp_logo_height{
			height: 69px;
		}
		.img-baby-height{
			width: 96%;
			object-fit: contain;
			margin-bottom: 50px;
		}
		.baby-width1{
			width: 60%;
		}
		.baby-width{
			width: 50%;
		}
		.baby-display{
			display: flex;
			margin-top: 30px;
		}
		.scift-left-best2{
			padding-top: 35px;
			padding-bottom: 50px;
			 margin-left: 11%;
			  margin-right: 11%;
		}
		.baby-trasform-bestseller{
			transform: translate(0px,-75px);
		}
		.filter_body{
			display: flex;
			 justify-content: space-between;
		}
		.filter_button{
				font-size: 12px !important;
				border: none; 
				font-family: Trebuchet MS, sans-serif !important;
				font-weight: 550 !important; 
				padding: 5px 10px;
				margin-top: 20px;
				// padding-right: 10px;
				// padding-left: 10px;
				border-radius: 6px;
		}
		// .product-list-description-baby{
		// width: 21%;
		// }
		.lush-strikehome{
			font-size: 13px !important;
				margin-right: 5px !important;
				text-decoration: line-through;
				color: #8080806e !important;
				font-weight: 500;
				margin-top:2%;
			text-decoration: line-through;
			color: #8080806e !important;
		}
		.baby-newcollection{
			width: 60%;
			background-color: white;
			border-radius: 10px;
		}
		.product-list-image-baby{
			max-width: 75%;
			border-radius: 100px;
			object-fit: contain;
    		display: block;
    		margin: auto
		}
		.newcollection-image-baby{
			max-width: -webkit-fill-available;
		}
		.baby_newprodname{
			margin-top:15px;
			margin-bottom: 15px;
			margin-left: 25px;
   			margin-right: 25px;
		}
		.sale-btn{
				width: 126px;
				font-size: 14px !important;
				height: 50px;
				border: none; 
				font-family: Trebuchet MS, sans-serif !important;
				font-weight: 550 !important; 
				background-color: #bb086c;
				color: white;
			
		}
		.baby-footer{
			display: flex !important;
			padding-top: 80px !important;
			padding-bottom: 80px !important;
		}
		.baby-footer-cont{
			margin-left: 10px;
			width: 20%;
			
		}
		.baby-footer-contmenu{
			margin-left: 10px;
			width: 10%;
		}
		.baby-footer-contmenu1{
			margin-left: 10px;
			width: 17%;
		}
		.baby-footer-cont1baby{
			display: flex;
			justify-content: end;
			margin-right: 40px;
		}
		.social-icons2{
			justify-content: left !important;
		}
		.baby_footheading{
			letter-spacing: 1px;
			font-family: system-ui;
			font-size: 15px!important;
			font-weight: 450;
			margin-left: 3%;
			color: white;
		}
		.baby-imglogo{
			object-fit: contain;
    width: 61%;
    height: 113px;
		}
.baby-newcol-price{
	font-size: 14px;
	color: #80c340;
	font-weight: 500;
	margin-right: 5px;
}
		.baby-newcol-name
		{
			color: #000;
			font-size: 14px;
			font-family: system-ui;
			font-weight: 500;
			overflow: hidden!important;
			display: -webkit-box!important;
			-webkit-line-clamp: 2!important;
			-webkit-box-orient: vertical!important;
			text-overflow: ellipsis!important;
			background-clip: text;
			-webkit-background-clip: text;
			display: inline-block;
		}
		.baby-newcol-name1
		{
			color: #f4f3f3;
			font-size: 15px;
    		font-weight: 700;
			font-family: system-ui;
		}
		.lush-newcol-name{
			font-size: 12px;
    		color: white;
			overflow: hidden !important;
			display: -webkit-box !important;
			-webkit-line-clamp: 7 !important;
			-webkit-box-orient: vertical !important;
			text-overflow: ellipsis !important;
			background-clip: text;
			-webkit-background-clip: text;
			display: inline-block;
		}
		.lush-newcol-name1{
			font-size: 12px;
    		color: white;
		}
		.schift-footer{
			display: flex !important;
		}
		.lush_footheading{
				font-family: Trebuchet MS, sans-serif!important;
				font-size: 11px !important;
				font-weight: 600;
				margin-left: 3%;
		}
		.lush-footer-cont{
			margin-left: 10px;
			width: 16%;
		}
		.lush-footer-cont1{
			margin-left: 10px;
			width: 32%;
		}
		.subscribelush{
			transform: translate(100%, -170%); 
			position: absolute;
		}
		.lush_footer_text1{
			color: #020a12;
			font-size: 10px;
			font-family: Trebuchet MS, sans-serif !important;
			cursor: pointer;
			margin-left: 3% !important;
		}
		.lush_footer_text{
			color: #020a12;
			font-size: 10.6px;
			font-family: sans-serif !important;
			cursor: pointer;
			margin-left: 3% !important;
		}
		.lush-footer-cont2{
			margin-left: 10px;
			width: 20%;
		}
		.lush-imglogo{
			width: 80%;
		}
		.baby-age-list{
			font-family: sans-serif;
			line-height: 1.25em;
			font-size: 18.5px;
			margin-top: 5px;
			font-weight: 600;
    		color: #0a0909;
		}
		.baby-age{
			display: flex;
			justify-content: space-evenly;
			margin-top: 50px;
			margin-left: 4%;
			margin-right: 5%;
		}
		.baby-age1{
			display: flex;
			justify-content: space-evenly;
			margin-top: 50px;
			margin-left: 10%;
			margin-right: 10%;
		}
		.pro_detail{
            min-height:80vh !important;
			max-height: fit-content !important;
        
        }
		.special-cart{
			width:50%
		  }
		.specialViewCart1{
			background-color:#2177b8f2 !important;
			color:$white !important;
			border:none !important;
			width:30%;
			margin-top: 2%;
			margin-left: -4% !important;
			margin-bottom: 20px;
		}
		.specialViewCart{
			background-color:#2177b8f2 !important;
			color:$white !important;
			border:none !important;
			width:55%;
			margin-top: 2%;
			margin-left: 22% !important;
			margin-bottom: 20px;
		}
		.vertical-line{
				display: flex;
				justify-content: center;
				
			}
			.special-listing-price{
				font-size: 14px;
				color: black;
				font-weight: 700;
			}
		.home-prod{
				
			.home-prod1{
				height: 200px;
				width: 275px;
				.prod-image{
					height: 148px;
					object-fit: contain;
					width:100% !important
				}
			}
		}
		.lush_cocen{
			text-align: center;
		}
		.orderdetails-image{
			width: 100% !important;
		}
		.baby_detail_view{
			width: 70% !important;
			height: 45px;
			color: #fff;
    background-color: #a9c70b;
    border-color: #a9c70b;
	display: flex;
    justify-content: space-evenly;
    padding-top: 2%;
	margin-top: 4px;
		}
		.baby-rotate{
    width: 10%;
		}
		.baby-rotate1{
			width: 100%;
				}
				.banner-img-lush{
					width: 100%;
    				margin-left: -50%;
				}
			.carousel-item:empty {
				display: none!important;
				margin-right: 100%!important;
			  }
			  .lushhomecard-1{
				width: 45.5%;
   				margin-left: 3%;
			  }
			  .lushhomesec-1{
				margin-top: 50px;
				display: flex;
			  }
			  
			  .lushoverlay1{
			  transform: translate(0px,-400%);
			  right: 28%;
			  position: absolute;
			  }
			  .lushovertext1{
				white-space: nowrap;
				font-size: 30px;
				font-weight: 500;
				font-family: Franklin Gothic Medium,Arial Narrow,Arial,sans-serif;
    			letter-spacing: 2px;
			  }
			  .lushovertext2{
				text-align: left;
				font-family: sans-serif;
    			letter-spacing: 1px;
				font-size: 15px;
				margin-top: 5px;
			  }
			  .btlush{
				font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
				font-size: 13px;
				padding-top: 5px;
   				padding-bottom: 5px;
				position: relative;
				top: 20px;
				width: 133px;
				border: 1px solid #a5c30b;
				letter-spacing: 1px;
			  }
			  .btlush1{
				font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
				font-size: 16px;
				padding-top: 5px;
   				padding-bottom: 5px;
				position: relative;
				top: 20px;
				width: 133px;
				border: 2px solid #a5c30b;
				letter-spacing: 1px;
			  }
			  .lushhomebes1{
				font-size: 22px;
				font-weight: 500;
				font-family: Franklin Gothic Medium,Arial,sans-serif;
    			letter-spacing: 2px;
				text-align: center;
			  }
			  .lushhomebes2{
				font-size: 14px;
				font-weight: 500;
				font-family: sans-serif;
    			letter-spacing: 2px;
				text-align: center;
				color: #3d3d3d;
				margin-bottom: 30px;
			  }
			  .lushhomebes3{
				font-size: 22px;
				font-weight: 500;
				font-family: Franklin Gothic Medium,Arial,sans-serif;
    			letter-spacing: 2px;
				text-align: center;
				color: #ffffff;
				margin-top: 5px;
			  }
			  .lushhomebes4{
				font-size: 14px;
				font-weight: 500;
				font-family: sans-serif;
    			letter-spacing: 2px;
				text-align: center;
				color: #ffffff;
				margin-bottom: 30px;
				margin-top: 10px;
			  }
			  .lushgrid{
				display: grid;
				grid-template-columns: repeat(4,1fr);
			  }
			  .lushsubscribe{
				font-size: small;
				width: 75%;
				padding-top: 5px;
				padding-bottom: 5px;
			
			  }
			  .lush-subbtn{
				width: 25%;
				border: none;
			  }
		}
		
		@media (max-width: 640px) {
			.lush_footer_text1{
				margin: 5px 0px !important;
			}
			.thumb2-lushlist{
				display: grid;
				grid-template-columns: repeat(2, 1fr);
			  }
			.lush-list-top{
				margin-top: 30px;
			}
			.rounder-corner-nav .owl-theme .owl-nav .owl-prev .fa, .rounder-corner-nav .owl-theme .owl-nav .owl-next .fa {
				color: white !important;
			
			}
			.button-overlay {
				position: relative;
				display: inline-block;
				.add_schift-btn {
					position: absolute;
					top: 10%;
					left: 90%;
					transform: translate(-50%, -50%);
				}
				.add_baby-btn{
					display: block;
				position: absolute;
				top: 10%;
				left: 15%;
				transform: translate(-50%, -50%);
				}
			}
			.baby-rotate{
   			 width: 30%;
			}
			.baby-rotate1{
   			 width: 30%;
			}
			.baby_filter_select{
				width: 35%;
			}
			.baby_mar_top{
				margin-top: 40px;
			}
			.baby_orderdetails-btn{
				width: 50% !important;
				justify-content: space-evenly !important;	
			}
			.babys_padding1{
				padding-bottom: 34px;
			}
			.babys_padding{
				padding-bottom: 35px;
			}
			.poweredbylogo1{
top: 97px !important;
			}
			.subscribelush{
				width: 90%;
				transform: translate(0, -210%); 
				position: absolute;
			}
.baby_beforefooter{
			width: 122%;
			transform: translateX(-41px);
		}
			
			.margintop-lush{
				margin-top: 40px;
			}
			.image-88baby{
				width: 100%;
			}
			.banner_baby{
				margin-bottom: 0px !important;
			}
			.DetailsviewCart {
				width: 160px!important;
			}
			.vistas-homebackground{
				margin-bottom: 30px;
			}
			.Lush_Delivery_change{
				margin-top: 10px;
			}
			.baby_detail_view{
				width: 85% !important;
				display: flex;
				justify-content: space-evenly;
				background-color: #a9c70b!important;
				border-color: #a9c70b;
				font-size: small;
				font-weight: 700;
				height: 35px;
				margin-top: 4px;
				padding-top: 2px;
				border: none!important;
				color: #fff!important;
			}
			.lushClear{
				margin-top: -5px;
			}
			.margin_top_12{
				margin-top: 12px;
			}
			.babycar{
				width: 40%;
			}
			.dropdown-menu{
				min-width: 9rem !important;
			}
			.rounder-corner-nav .owl-theme .owl-nav{
				width: 98% !important;
				z-index: 1000 !important;
				margin-left: 0% !important;
			}
			.special-carousel{
				bottom: -31px !important;
			}
			.special-cart{
				width:63% !important
			  }
			.dropspecial{
				width: 96% !important;
				border-color: #4890ba;
				align-items: center;
				justify-content: center;
				border-radius: 10px;
			}
			.dropspecial2{
				width: 97% !important;
				border-color: #4890ba;
				align-items: center;
				justify-content: center;
				border-radius: 10px;
			}
			.dropspecial1{
				width: 300%;
				border-color: #4890ba;
				align-items: center;
				justify-content: center;
				border-radius: 10px;
			}
			.detail-preview{
				margin-top: -13px;
			}
			.specialViewCart{
				background-color:#2177b8f2 !important;
				color:$white !important;
				border:none !important;
				width: 77%;
    			margin-top: 12%;
				margin-bottom: 20px;
			}
			.specialViewCart1{
				background-color:#2177b8f2 !important;
				color:$white !important;
				border:none !important;
				width: 40%;
				margin-top: 2%;
				margin-bottom: 20px;
			}
		.vertical-line{
				margin-top: 20px;
			}
		.home-container4{
		
				width: 190px;
			}
			.home-prod{
				height: 140px;
				width: 195px;
				.home-prod1{
					height: 140px;
					width: 190px;
					.prod-image{
						height: 88px;
					}
					.home-prod-title{
						width: 130px;
						font-size: 15px;
					}
				}
			}

			.product-list-avon{
				margin-left: 20px;
			}
		}


		@media (max-width: 640px) {
			.row1{
					height: 300px;
					transform: translate(0px, 24px);
				}
			}

			@media (min-width: 640px) {
				.button-overlay {
					position: relative;
					display: inline-block;
					.add_schift-btn {
					display: none;
					}
					.add_baby-btn{
						display: block;
					position: absolute;
					top: 15%;
					left: 25%;
					transform: translate(-50%, -50%);
					}
				}
				.button-overlay:hover {

					.add_schift-btn {
						display: block;
					position: absolute;
					top: 10%;
					left: 90%;
					transform: translate(-50%, -50%);
				}
			}
				.feedback_head{
					justify-content: center;
					display: flex;
					font-size: larger;
					font-family: cursive;
					margin-bottom: 25px;
					color: aliceblue;
				}
				.feedback_title{
					color: aliceblue;
				}
				.special-col{
					max-width: 250px !important;
				}
				.footer-col1{
					
					width: 27%;
				padding: 5px;
				margin:5px;
				
				.social-icons1{
				max-width: 370px;
				
				}
				}
				}
				
				@media (max-width: 640px) {
				.footer-col1{
						
							padding: 5px;
							margin:5px;
							
							
							.social-icons1{
							max-width: 370px;
							margin-left: 0px;
							}
							}
				}


				@media (min-width: 640px) {
					.sub-list_baby{
						color: #bb086c !important;
						cursor: pointer;
						font-weight: 500;
						font-size: 14px;
					}
					.avon-chart{
						width: 103%;
						
					}
				}


				@media (max-width: 640px) {
					.avon-chart{
						width: 100%;
						
					}
					.home-prod-sample{
						.home-prod1-sample{
							height: 100px;
							width: 70px;
							.prod-image{
								height: 148px;
								object-fit: contain;
								width:100% !important
							}
						}
					}
				.product-list-avon{
						margin-left: 20px;
						
					}
				}
				
				@media (min-width: 640px) {
					.hod_imagecol{
						flex: 0 0 60% !important;
        max-width: 60% !important;
					}
					.hod_desc{
						flex: 0 0 40% !important;
        max-width: 40% !important;
					}
				.home-prod-sample{
					.home-prod1-sample{
						height: 100px;
						width: 175px;
						.prod-image{
							height: 148px;
							object-fit: contain;
							width:100% !important
						}
					}
				}
				.product-list-avon{
						margin-left: 0px;
						
					}
				}
.deera-img>div>.baby_newone{
	position: absolute;
	bottom: 10px;
	right: 5px;
}
				@media (min-width: 640px) {
					.carousel-inner{
								margin-top: -10px;
								background-color: white;
							}
							.active_header{
								color:#6d6c6a
							}
							
							.tabs li.active-tab{
								color: #9b0858 !important;
							}
							.rounder-corner-nav .owl-theme .owl-nav {
								margin-left: 0px !important;
								display: flex !important;
								justify-content: space-between !important;
								pointer-events: none;
								width: 100% !important;
								position: absolute !important;
								top: 50% !important;
							}
							#myBtn2{

							}
					}
					
					@media (max-width: 640px) {
						.lush_distruck{
							width: 100%;
							display: flex;
							justify-content: space-between;
						}
						.lush-truck-image{
							width: 75%;
						}
						.lush-truck{
							width: 25%;
						}
						.lushbanho{
							margin-top: 30px;
						  }
						.choisebuttonbaby{
							width: 100px;
							height: 45px;
							border: 1px solid #e5e2e2;
							background-color: white;
							font-family: system-ui;
							font-size: 14px;
							padding: 0px 3px;
							margin-bottom: 10px;
							margin-right: 10px;
							margin-top: 10px;
							border-radius: 7px;
						  }
						.special-simple-image{
							width: 100%;
						}
						.special-checkout{
							display: flex;
							justify-content: center;
						    width: 109px;
						}
						.special-cartprice{
							margin-left: -15px;
						}
					.carousel-inner{
									margin-top: 20px;
								}
								.product-list-image-avonseals1{
									min-height: 190px;
									max-height: 275px;
									margin-left: 17px;
									width: 88%!important;
									border-radius: 10px;
								}
								.special-footertext{
									margin-left: 14px;
								}
								.special-footer-logo{
									width: 100%;
    								max-width: none !important;
    								min-width: 127% !important;
								}
								.special-footer-logo1{
								display: flex;
    							justify-content: center
								}
								.product-list-description-lush{
									padding: 10px 5px;
									margin-bottom: 20px;
									height: 250px;
									
								  }

							}
					
					@media (min-width: 640px) {
						.lush_distruck{
							width: 90%;
							display: flex;
							justify-content: space-between;
						}
						.lush-truck{
							width: 25%;
						}
						.lush-truck-image{
							width: 65%;
						}
						.filter-clear {
							padding-top: 4px !important;
						}
						.product-list-image-baby1{
							width: 100% ;
							// width: 60% !important;
							border-radius: 10px ;
							object-fit: contain;
							
						  }
						  .ngxImageZoomContainer{
							width: auto !important;
							height: auto !important;
						}
						.ngxImageZoomThumbnail{
							width: 100%;
						}
						  .product-list-price2-baby{
							font-size: 17px ;
							color: #a9c80b !important;
							font-weight: 600 !important;
							
						  }
						  .product-list-description-baby1{
							padding: 10px 5px;
    margin-bottom: 5px;
						  }
						  .product-list-description-lush{
							padding: 10px 5px;
    						margin-bottom: 20px;
							height: 190px;
							
						  }
						  .lushbanho{
							margin-left: 3%;
							margin-right: 3%;
							margin-top: 30px;
						  }
						  .listProduct1 {
							cursor: pointer;
							margin: 0px 4px!important;
							
						}
						.listProducthome{
							width: 100%;
						}
						.listProducthome22{
							width: 95%;
						}
						.lushbests{
							width: 97%;
							margin-left: 3%;
							padding-bottom: 4%;
						}

						  .product-list-price-baby{
							// font-size: 20px ;
							// padding-left: 17px;
							font-size: 15px ;
							margin-right: 5px;
							color: #43950d !important;
							font-weight: 600 !important;
						  }
						  .product-list-tile-baby1{
							height: 40px;
							font-family: system-ui;
							font-size: 15px !important;
							color: #000 !important;
							font-weight: 600 !important;
							overflow: hidden !important;
							display: -webkit-box !important;
							-webkit-line-clamp: 2 !important;
							-webkit-box-orient: vertical !important;
							text-overflow: ellipsis !important;
							background-clip: text;
							-webkit-background-clip: text;
							display: inline-block;
							// text-align: center;
						  }
						.product-list-cards-baby1{
							border:none;
							border-radius: 10px;
							padding-top: 30px;
						
						}
						.product-list-cards-baby2{
							border:none;
							border-radius: 10px;
						}
						.product-list-cards-Lushnn{
							border:none;
							border-radius: 10px;
						}
						.special-listing-width{
							width: 99%;
							margin-left: 10px !important;
						}
						
						.special-sec-banner{
							width: 100%;
							margin-left: 0px;
						}
						.special-sub-category{
							font-size: 12px;
						}
						.home-special-banner{
											margin-top: 35px;
										}
						.product-list-image-avonseals1{
							min-height: 190px;
							max-height: 275px;
							width: 62%!important;
							border-radius: 10px;
						}
						.special-img2{
							text-align: center;
						}
									.footer-social-icons{
										display: flex;
									.footer-col1{
										
										justify-content: center !important;
									padding: 5px;
									margin-left: auto;
									.social-icons1{
									max-width: 500px;
									
									}
									}
									}
									.footer-row-three1{
										background-color: #020a12;
										height: 61px;
									}
									.tooltip-container {
										position: relative;
										display: inline-block;
									  }
									  
									  .tooltip-text {
										visibility: hidden;
										position: absolute;
										bottom: 125%; /* Position above the text */
										left: 50%;
										transform: translateX(-50%);
										overflow: visible;
										background-color: #fff8e5cc;
										color: black;
										text-align: center;
										padding: 5px;
										border-radius: 4px;
										z-index: 1000;
										width: 150px;
										font-size: 13px;
										opacity: 0;
										transition: opacity 0.2s;
									  }
									  
									  .tooltip-container:hover{
									  overflow: visible !important; 
									  .tooltip-text {
										
										visibility: visible;
										opacity: 10 !important; /* Fade in effect */
									  }
									}
								}
									@media (max-width: 640px) {
										.baby_top{
											padding-top: 5px;
										}
										.product-list-tile-baby1{
										font-size: 14px!important;
										color: #000!important;
										font-weight: 600!important;
										overflow: hidden;
										display: -webkit-box!important;
										-webkit-line-clamp: 2!important;
										-webkit-box-orient: vertical!important;
										text-overflow: ellipsis!important;
										height: 42px;
									}
									.product-list-price-baby {
										padding-left: 1px!important;
										font-size: 14px;
										color: #43950d!important;
										font-weight: 600!important;
									}
										.product-list-image-baby2{
											width: 100%;
										}
										.baby-img{
											width: 100%;
										}
										.product-list-description-baby2{
											margin-left: 2%;
											margin-bottom: 2px;
											width: 98%;
										}
										.cartButtonvarbaby{
											background-color: #a9c70b  !important;
											font-size: small;
											font-weight: bold;
											height: 35px;
											width: 85%;
											border: none !important;
											color: white !important;
										  }
										  .cartButtonvarbaby2{
											background-color: black !important;
											border-color: black;
											font-size: small;
											font-weight: bold;
											height: 35px;
											margin-top: 10px;
											width: 85%;
											border: none !important;
											color: white !important;
										  }
										  .checkpinLu{
											width: 26%;
										  }
										  .linen-login{
											margin-top: 25px;
										  }
										  .trash-position{
											top: -28px !important;
										  }
										  .cost-deera{
											top: 58px !important;
										  }
										  .lushbtncant{
											margin-left: 12px;
										  }
										  .lushchoima{
											margin-left :8px;
										  }
										.offer-baby{
											background-color: #2dbc0a;
											color: white;
											text-align: center;
											width: 100px;
											height: 26px;
											border-radius: 10px;
										}
										.footer-social-icons{
											display: block;
									.footer-col1{
													justify-content: center !important;
												padding: 5px;
												margin:5px;
												
												
												.social-icons1{
												max-width: 350px;
												margin-left: 0px;
												}
												}
									}
									.social-icon3{
										
										font-size: 12px;
   										color: #f0f8ff;
									}
									.footer-row-three1{
										background-color: #020a12;
										height: 45px;
									}
									
								}
								.choisebuttonbaby.active
								{
									width: 100px;
									border-style:none!important;
									color: $white !important;
									margin: 11px 11px 0px 0px;
									background-color:$primary !important;
									font-weight:bold;
									border-radius: 7px;
								}
								@media (min-width: 640px) {
									.cartButtonvarbaby{
										background-color: #a9c70b  !important;
										font-size: small;
										font-weight: bold;
										height: 45px;
										width: 70%;
										border: none !important;
										color: white !important;
									  }
									  .choisebuttonbaby{
										width: 100px;
										height: 45px;
										border: 1px solid #e5e2e2;
										background-color: white;
										font-family: system-ui;
										font-size: 14px;
										margin-bottom: 10px;
										padding: 0px 3px;
										margin-right: 10px;
										margin-top: 10px;
										border-radius: 7px;
									  }
									  .cartButtonvarbaby2{
										background-color: black !important;
										border-color: black;
										font-size: small;
										font-weight: bold;
										height: 45px;
										margin-top: 25px;
										width: 70%;
										border: none !important;
										color: white !important;
									  }
									.offer-baby{
										background-color: #2dbc0a;
										color: white;
										text-align: center;
										width: 100px;
										height: 26px;
										border-radius: 10px;
									}
									.special-aboutcert{
										width: 25%;
									}
									.special-about{
										display: flex;
										margin-bottom: 20px;
									}
									.special-dropitem{
										display: block;
										width: 100%;
										padding: 0.25rem 1.5rem;
										clear: both;
										font-weight: 400;
										color: #212529;
										text-align: center !important;
										white-space: nowrap;
										background-color: transparent;
										border: 0;
									}
									.special-dropitem:hover{
										background-color: #f3e055 !important;
									}
									.special-dropdownmenu{
										min-width: 60% !important;
										max-width: fit-content;
									}
									.special_owner{
										margin-left: 20px !important;
									}
									// .slider2{
									// 	display:flex;
									// 	width: 89%;
									// 	transform: translate(64px, -10px);
									// }
									.product-list-image-specialwires{
										min-height: 133px ;
										max-height: 200px;
										width: 100% !important;
										}
									.special-owner{
										margin-left: 20px;
									}
									.special-owner1{
										margin-left: 20px;
									}
									.product-list-cards-specialwires2:hover{
										
										box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
										.product-list-cards-specialwires1{
											
											padding: 10px;
											object-fit: contain !important;
										}
										.specialwires-description{
											box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
										    height: 224px
										}
										
									}
									.slider3 {
										width: 77%;
										margin-left: 11%;
										display: flex;
									}
									.slider4 {
										width:68%;
										margin-left: 16%;
										display: flex;
									}
									.product-list-description-special{
										width: 100%;
										padding: 10px 15px;
    									margin-bottom: 10px;
									}
									.product-list-description-special1{
										
   										width: 100%;
										padding: 10px 6px;
    									margin-bottom: 10px;
									}
									.special_home_prodname{
										display: flex !important;
    									justify-content: center !important;
										width: 100%;
										
										margin-bottom: 25px;
									}
									.social-icon3{
										font-size: 12px;
										color: aliceblue ;
									}
									.content-title3{
										text-shadow: none!important;
										font-family: proxima-regular,"sans-serif"!important;
										color: #373230!important;
										padding-top: 0!important;
										margin-top: 8px!important;
										font-size: 18px!important;
										font-weight: 500!important;
										max-width: 105%!important;
											}
									.product-title{
										margin-left: 10%;
										width: 80%;
									}
									.slider1{
												width: 94%;
												margin-left: 0%;
												display: flex;
											}
									.chem-components{
												display: flex;
											}
									}
									@media (max-width: 640px) {
										.new_miles {
											margin-left: 17%;
											width: 67%;
											border-top-left-radius: 100px;
											border-top-right-radius: 100px;
											padding: 15px 5px;
											box-shadow: 0 5px 6px 1px rgba(0, 0, 0, 0.2);
											margin-bottom: 25px;
											min-height: 210px;
											position: relative;
											background: linear-gradient(to bottom, #d8d8d8, #e8e9ee);
										  }
										.special-aboutcert{
											width: 100%;
										}
										.special-aboutimg{
											width: 88%;
    										margin-bottom: 10px;
										}
										.special-dropdownmenu{
											min-width: 160% !important;
											max-width: fit-content;
										}
										.special-dropitem{
											display: block;
											width: 100%;
											padding: 0.25rem 1.5rem;
											clear: both;
											font-weight: 400;
											color: #212529;
											text-align: center !important;
											white-space: nowrap;
											background-color: transparent;
											border: 0;
										}
										.product-list-image-specialwires{
											min-height: 190px ;
											max-height: 270px ;
											width: 81% !important ;
											// width: 60% !important;
											border-radius: 10px ;
											object-fit: contain;
											
										  }
										  
										  .product-list-image-specialwires1{
											  min-height: 190px;
											  max-height: 270px;
											  width: 81%!important;
											  border-radius: 10px;
											  object-fit: contain;
										  }
										.special-form{
											margin-top: 1px !important;
										}
										.product-list-cards-specialwires2{
											display: flex;
											box-shadow: 0 0 10px 4px #00000040;
											margin-bottom: 10px;
											margin-left: 5px;
											width: 97%;
										}
										.product-list-cards-specialwires3{
											display: flex;
											box-shadow: 0 0 10px 4px #00000040;
											margin-bottom: 10px;
											margin-left: 4px;
											width: 96%;
										}
										.specialwires-description{
											width: 100%;
										}
										.product-list-cards-specialwires2:hover{
										
											box-shadow: none;
											.product-list-cards-specialwires1{
												
												padding: 10px;
												object-fit: contain !important;
											}
											.specialwires-description{
												box-shadow: none;
											
											}
											
										}
										.product-list-cards-specialwires1{
											
											display: flex;
											box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.25);
											margin-bottom: 10px;
											margin-left: 14px;
											width: 93%;
										}
										.cartButtonkubweight{
											font-size: 9px !important;
											padding: 4px;
																  font-weight: bold;
															 
																  margin-top: 4px;
																  background-color: transparent !important;
																  border: 1px solid #2177b8f2 !important;
																  color: #091214 !important;
																  background-color: grey;
																  border-radius: 55px;
										
										  }
										.product-list-description-special1{
											margin-left: 8px;
											   width: 55%;
										
										}
										.special_home_prodname{
											display: flex !important;
    										justify-content: center !important;
											width: 85%;
											margin-left: 5%;
											margin-bottom: 30px;
										}
									.content-title2{
											text-shadow: none!important;
												font-family: proxima-regular,"sans-serif"!important;
												color: #373230!important;
												
												padding-top: 0!important;
												margin-top: 8px!important;
												font-size: 22px!important;
												font-weight: 700!important;
												max-width: 105%!important;
										}
										.content-title3 {
											text-shadow: none!important;
											font-family: proxima-regular,"sans-serif"!important;
											color: #373230!important;
											padding-top: 0!important;
											margin-top: 8px!important;
											font-size: 18px!important;
											font-weight: 500!important;
											max-width: 92%!important;
										}
									.slider1{
											width: 93%;
											margin-left: 0%;
											display: flex;
										}
									// .slider2{
									// 		display:flex;
									// 		width: 89%;
									// 		transform: translate(11px, -10px);
									// 	}
										.slider3 {
											width: 85%;
											margin-left: 6%;
											display: flex;
											height: 250px;
										}
										.slider4 {
											width: 85%;
											margin-left: 6%;
											display: flex;
										}
									.row3{
									  transform: translate(30px,0px);
									}
									.product-title{
										margin-left: 6%;
										width: 94%;
										transform: translate(-12px, 0px);
									}
									.product-list-description-special{
										margin-left: 0px;
									}
									.title1{
										font-size: 29px !important;
									}
									.chem-components{
										display: grid;
    									justify-content: center;
									}
									}
									.special-addtocart{
										margin-top: 30px;
										padding: 0 3rem !important;
									}
									.button-62 {
										background: linear-gradient(to bottom right, #0095da, #33507a);
										border: 0;
										margin-bottom: 20px;
										border-radius: 12px;
										color: #FFFFFF;
										cursor: pointer;
										display: inline-block;
										font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
										font-size: 16px;
										font-weight: 500;
										line-height: 2.5;
										outline: transparent;
										padding: 0 1rem;
										text-align: center;
										text-decoration: none;
										transition: box-shadow .2s ease-in-out;
										user-select: none;
										-webkit-user-select: none;
										touch-action: manipulation;
										white-space: nowrap;
									  }
									  .product-list-cards-specialwires2{
										box-shadow: #00000005 0 1px 3px, #1b1f2326 0 0 0 1px;
										border: none;
										border-radius: 10px;
										width: 282px;
										height: 445px;
									  }
									  .product-list-cards-specialwires3{
										border: none;
										border-radius: 10px;
										min-width: 80%;
										max-width: 100%;
									  }
									  .button-62:not([disabled]):hover {
										box-shadow: 0 0 .25rem rgba(9, 13, 116, 0.5), -.125rem -.125rem 1rem rgba(51, 142, 228, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
										
									  }
									  .button-62:disabled {
										background: gray;
									  }
									  #dropdownMenuButton:checked + .dropdown-menu.show {
										display: none !important;
									  }
									  #dropdownMenuButton:checked + .dropdown-menu{
										display: none !important;
									  }
									  #dropdownMenuButton:checked + .special_click{
										display: none !important;
									  }
									  .sub-list3.active{
										font-weight: 700;
										color: #ffc107!important;
										text-transform: uppercase;
									}
									.sub-list_baby.active{
										font-weight: 700;
										color: #43950d!important;
									}
									.sub-list_baby :hover{
										color: #43950d!important;
									}
									.specialsize{
										font-size: 12px !important;
										color: black !important;
									}
									.sub-list3
									{
										text-transform: uppercase;
										font-weight: 700;
										color: #bb086c !important;
										cursor: pointer;
									}
									.dropdown-item.active, .dropdown-item:active {
										color: #ffffff;
										text-decoration: none;
										background-color: #0195da;
									}
									#prodName {
										font-size: 1.6rem;
										color: #101112 !important;
									}
									.colour-select-container{
										margin-left: 10px;
										margin-right: 10px;
										display: grid;
										justify-content: center;
										grid-template-columns: repeat(3,1fr);
									}
									.cartButtonspecialweight{
										font-size: small;
										padding: 7px;
										font-weight: bold;
										margin-top: 4px;
										background-color: transparent !important;
										border: 1px solid #2177b8f2 !important;
										color: #091214 !important;
										background-color: grey;
										border-radius: 15px;
										margin-right: 13px;
									  }
									  .cartButtonspecialweight.active{
										background-color: #0195da !important;
										color: white !important;
									  }
									  .cartButtonspecialweight:hover{
										background-color: #336699f2 !important;
									  }
									  .cartButtonspecialweight1{
										font-size: small;
										padding: 7px;
										font-weight: bold;
										margin-top: 4px;
										background-color: transparent !important;
										border: 1px solid #2177b8f2 !important;
										color: #091214 !important;
										background-color: grey;
										border-radius: 15px;
										margin-right: 13px;
										margin-bottom: 30px;
									  }
									  .cartButtonspecialweight1.active{
										background-color: #0195da !important;
										color: white !important;
									  }
									  .cartButtonspecialweight1:hover{
										background-color: #336699f2 !important;
									  }
									  .special_comp_title{
										font-size: large;
										font-weight: bold;

									  }
									  #myBtn2 {
										display: none;
										position: fixed;
										bottom: 40px;
										right: 20px;
										z-index: 99;
										font-size: 17px;
										border: none;
										outline: none;
										background-color: $pallet2;
										opacity: 0.7;
										color: $white;
										cursor: pointer;
										padding: 8px 18px;
										border-radius: 50%;
										font-weight: bold;
										box-shadow: 0 3px 16px 0 #555;
									  }
									 .about-specialtext{
										font-size: 20px;
									 }
									 .certification_box{
										margin-right: 30px;
										margin-bottom: 40px;
									 }
									.p_ico {
										width: 100%;
										margin: 15px;
									}
									.main_certification_c {
										margin: 20px 0px 50px 0px;
									}
									.main_certification_c {
										margin: 20px 0px 50px 0px;
									}
									  #myBtn2:hover {
										background-color: $pallet2;
										opacity: 1;
										color: $white;
										
									  }
									  .special-certimg{
										width: 100%;
										height: auto;
									  }
									  .logo-section{
										margin-bottom: 10px;
										background-color: white !important;
										margin-top: 10px;
									  }
									  .logo-section1{
										background-color: white !important;
										margin-top: 10px;
									  }
									  .logo-images{
										object-fit: contain;
									}
									.product-list-tile-special{
										font-size: 15px !important;
										color: #0095da !important;
										font-weight: 700 !important;
										overflow: hidden !important;
										display: -webkit-box !important;
										-webkit-line-clamp: 2 !important;
										-webkit-box-orient: vertical !important;
										text-overflow: ellipsis !important;
										background-clip: text;
										-webkit-background-clip: text;
										display: inline-block;
									  }
									  .thumb1-special {
										display: grid;
										grid-template-columns: repeat(3, 1fr);
										
									  }
									  .special-img{
										text-align: center;
									  }
									  .special-img1{
										text-align: center;
									  }
									  .special-atc{
										width: 35%;
										font-size: small;
										font-weight: bold;
										margin-top: 4px;
										background-color: transparent !important;
										border: 3px solid #5c7fad !important;
										color: #091214 !important;
										background-color: grey;
									  }
									  .special-atc1{
										font-size: small;
										font-weight: bold;
										width: 67%;
										margin-left: 17%;
										margin-top: 4px;
										background-color: transparent !important;
										border: 3px solid #003b85 !important;
										color: #091214 !important;
										background-color: grey;
									  }
									  @media (max-width: 640px) {
										.special-owner1{
											width: 88% !important;
											margin-left: 20px;
											margin-right: 20px;
										}
										.special_owner{
											margin-left: 0px;
										}
										.special-img{
											width: 50%;
										  }
										  .special-img1{
											width: 80%;
											margin-top: 25px;
										  }
										  
									  .thumb1-special{
										display: grid;
										grid-template-columns: repeat(1, 1fr);
									  }
									  .special-atc{
 
										
										margin-left: 10%;
										margin-top: 3px;
									  }
									  .special-atc1{
									   
										width: 53%;
									   
									  }
									}

								